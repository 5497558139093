import { getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store from '@/store'

/**
 * Модуль для туториалов по работе с платформой
 * - todo
 */

@Module({
  dynamic: true,
  name: 'MasterTutorials',
  namespaced: true,
  store,
})
class MasterTutorials extends VuexModule {
  // ---------------------------- Main Tutorial ---------------------------- >>
  public mainTutorialIsActive = false
  public mainTutorialStep: number | null = null

  @Mutation
  setMainTutorialActivity(value: boolean) {
    this.mainTutorialIsActive = value
  }

  @Mutation
  setMainTutorialStep(payload: number | null) {
    this.mainTutorialStep = payload
  }

  // ---------------------------- Mini Tutorial ---------------------------- >>
  public lessonExerciseTutorialActive = false

  @Mutation
  setMiniTutorialActivity(value: boolean) {
    this.lessonExerciseTutorialActive = value
  }
}

const MasterTutorialsModule = getModule(MasterTutorials)

export default MasterTutorialsModule
