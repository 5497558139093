












































































import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator'
import { BubbleMenu, Editor, EditorContent } from '@tiptap/vue-2'
import { Color } from '@tiptap/extension-color'
import CharacterCount from '@tiptap/extension-character-count'
import Highlight from '@tiptap/extension-highlight'
import Image from '@tiptap/extension-image'
import Link from '@tiptap/extension-link'
import Placeholder from '@tiptap/extension-placeholder'
import StarterKit from '@tiptap/starter-kit'
import Subscript from '@tiptap/extension-subscript'
import Superscript from '@tiptap/extension-superscript'
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import Typography from '@tiptap/extension-typography'
import Underline from '@tiptap/extension-underline'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// components
import SwitchInput from '@/components/_uikit/controls/SwitchInput.vue'
import TiptapToolbar from '@/components/_uikit/editor/tiptap/TiptapToolbar.vue'
// store
import EditorModule from '@/store/modules/editor'
// types
import { IImageForm } from '@/components/_uikit/editor/tiptap/ImageModal.vue'

const CustomImage = Image.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      height: {
        default: null,
        renderHTML: (attr: any) => {
          return {
            height: attr.height,
          }
        },
      },

      width: {
        default: null,
        renderHTML: (attr: any) => {
          return {
            width: attr.width,
          }
        },
      },
    }
  },
})

@Component({
  components: {
    BubbleMenu,
    EditorContent,
    SwitchInput,
    TiptapToolbar,
  },
})
export default class TiptapEditor extends Mixins(NotifyMixin) {
  @Prop({ default: null })
  private value!: string

  @Prop({
    default: 'MASTER',
    validator (value: string): boolean {
      return !!value.match(/(MASTER|MENTOR)/)
    },
  })
  private type!: 'MASTER' | 'MENTOR'

  // Включение только режима для чтения. Редактор при значении "true" не инициализируется
  @Prop({ default: false })
  private readonly!: boolean

  @Prop({ default: 'Напишите что-нибудь...' })
  private placeholder!: string

  @Prop({ default: false })
  private borderTopNone!: boolean

  @Prop({ default: false })
  private notPaddingY!: boolean

  @Prop({ default: true })
  private showPreviewSwitcher!: boolean

  @Prop({ default: false })
  private isViewCountWords!: boolean

  // Отображение кнопки "Показать/скрыть всё"
  @Prop({ default: false })
  private isShowButtonCollapse!: boolean

  // Растянуть контент по всей высоте страницы за исключением хедера, тулбара и т.д.
  @Prop({ default: false })
  private fillContent!: boolean

  @Prop({ default: () => ([]) })
  private showButtonList!: string[]

  @Prop({ default: undefined })
  private limit?: number

  @Prop({ default: null })
  private maxHeight!: number | null

  private get maxHeightContent(): number {
    if(this.maxHeight) {
      return this.maxHeight
    } else {
      return this.$vuetify.breakpoint.width > 768 ? this.windowHeight - this.toolbarHeight - 200 : this.windowHeight - this.toolbarHeight - 50
    }
  }

  private get heightContent(): number {
    // сумма высот всяких бордеров и прочего
    const staticHeight = window.innerWidth >= 600 ? 11 : 19

    if (this.windowHeight <= 400 || window.innerWidth <= 768) {
      return this.windowHeight - this.headerDrawerHeight - this.headerNoteHeight - this.footerNoteHeight - this.toolbarHeight - staticHeight
    }
    return this.windowHeight - this.headerHeight - this.headerDrawerHeight - this.headerNoteHeight - this.footerNoteHeight - this.toolbarHeight - staticHeight
  }

  private get wordsCount() {
    const matches = this.value ? this.value.replace(/<p>/g, ' ').replace(/<\/?[^>]+>/g, '').match(/(^[^\s|\w|\d|а-я|А-Я])(?=\s)|(\s[^\s|\w|\d|а-я|А-Я]\s)|(?:\s)([^\s|\w|\d|а-я|А-Я]$)/g) : null
    const count = matches ? matches.length : 0

    // Количество неразрывных пробелов, между которыми нет проблелов (их нужно прибавить к общему количеству слов)
    const nbsp = this.value ? this.value.match(/&nbsp;/g)?.length || 0 : 0

    // Количество неразрывных пробелов, до или после которых есть пробел (их нужно вычесть от общего количества слов)
    const spaceNBSP = this.value ? this.value.match(/&nbsp;(=?\s)|(?:\s)&nbsp;/g)?.length || 0 : 0

    // Количество абзацев, содержащих только неразрывные пробелы (их нужно * 2 и вычесть)
    const emptyParagraph = this.value ? this.value.match(/<p>&nbsp;<\/p>/g)?.length || 0 : 0

    return this.editor.storage.characterCount.words() - count - spaceNBSP - emptyParagraph * 2 + nbsp
  }

  private get charactersCount() {
    return this.editor.storage.characterCount.characters()
  }

  private isPreview = false
  private lines = 0
  private observer = new ResizeObserver(size => this.changeLines(size[0].contentRect.height))

  private editor: any = null

  private color = ''
  private showChooseColor = false
  private showBubbleChooseColor = false

  private marker = ''
  private showChooseMarker = false
  private showBubbleChooseMarker = false

  private showChooseTable = false
  private showBubbleChooseTable = false

  private windowHeight = window.innerHeight
  private toolbarHeight = 0
  private toolbarCollapse = window.innerWidth <= 768
  private previousWidth = window.innerWidth

  private colorSelect: Element[] = []
  private markerSelect: Element[] = []
  private tableSelect: Element[] = []

  // Для растяжки контента на всю высоту страницы
  private headerHeight = 0
  private headerDrawerHeight = 0
  private headerNoteHeight = 0
  private footerNoteHeight = 0
  private headerEl: HTMLDivElement | null = document.querySelector('.header')
  private headerDrawerEl: HTMLDivElement | null = document.querySelector('.drawer__head')
  private headerNoteEl: HTMLDivElement | null = document.querySelector('.note__header')
  private footerNoteEl: HTMLDivElement | null = document.querySelector('.note__footer')

  private mounted() {
    if (this.fillContent) {
      this.headerNoteEl = document.querySelector('.note__header')
      this.footerNoteEl = document.querySelector('.note__footer')
    }

    this.isPreview = this.readonly
    document.addEventListener('click', this.closeChooseColor)
    window.addEventListener('resize', this.resizeWindow)
    this.editor = new Editor({
      content: this.value,
      editable: !this.readonly,
      editorProps: {
        attributes: {
          spellcheck: 'false',
        },
      },
      extensions: [
        Color,
        CustomImage.configure({
          HTMLAttributes: {
            display: 'flex',
            style: 'max-width: 100%',
            textAlign: 'center',
          },
          inline: true,
        }),
        CharacterCount.configure({
          limit: this.limit,
        }),
        Highlight.configure({ multicolor: true }),
        Link.configure({
          HTMLAttributes: {
            class: 'text-editor__link',
            target: '_blank',
          },
          openOnClick: false,
        }),
        Placeholder.configure({
          placeholder: this.placeholder,
        }),
        StarterKit,
        Subscript,
        Superscript,
        Table.configure({
          resizable: true,
        }),
        TableCell,
        TableHeader,
        TableRow,
        TaskItem.configure({
          nested: true,
        }),
        TaskList,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
        TextStyle,
        Typography,
        Underline,
      ],
    })

    this.editor.on('create', this.handleEditorReady)
    this.editor.on('update', this.handleInput)
    this.$bus.$on('tiptapSetContent', this.handleSetContent)

    this.$nextTick(() => {
      if (this.fillContent)
        this.heightElemFill()

      if (this.$refs.toolbar) {
        this.observer.observe((this.$refs.toolbar as Vue).$el as Element)
        this.colorSelect = [...document.querySelectorAll('.js-color-select')]
        this.markerSelect = [...document.querySelectorAll('.js-marker-select')]
        this.tableSelect = [...document.querySelectorAll('.js-table-select')]
      }
    })
  }

  private heightElemFill() {
    this.headerHeight = this.headerEl?.offsetHeight || 0
    this.headerDrawerHeight = this.headerDrawerEl?.offsetHeight || 0
    this.headerNoteHeight = this.headerNoteEl?.offsetHeight || 0
    this.footerNoteHeight = this.footerNoteEl?.offsetHeight || 0
  }

  private handleInput() {
    this.$emit('input', this.editor.getHTML())
  }

  private handleSetContent(value: string) {
    this.editor.commands.setContent(value)
  }

  private beforeDestroyed() {
    window.removeEventListener('resize', this.resizeWindow)
    if (this.$refs.toolbar) {
      this.observer.unobserve((this.$refs.toolbar as Vue).$el as Element)
    }
  }

  private destroyed() {
    this.$bus.$off('tiptapSetContent', this.handleSetContent as any)
    document.removeEventListener('click', this.closeChooseColor)
    this.editor.destroy()
  }

  private changeLines(height: number) {
    this.toolbarHeight = height
    this.lines = Math.abs(Math.ceil((height - 44) / 44))
  }

  private resizeWindow() {
    this.windowHeight = window.innerHeight
    if (this.previousWidth !== window.innerWidth)
      this.toolbarCollapse = window.innerWidth <= 768
    this.previousWidth = window.innerWidth

    if (this.fillContent)
      this.heightElemFill()
  }

  private handleAddLink (form: { url: string, blank: boolean }) {
    this.editor
      .chain()
      .focus()
      .extendMarkRange('link')
      .setLink({ href: form.url, target: form.blank ? '_blank' : '' })
      .run()
  }

  private handleAddImage(form: IImageForm[]) {
    form.forEach(file => {
      const attr: { alt: string, height?: string, src: string, width?: string } = {
        alt: file.alt,
        src: file.url,
      }
      if (file.height)
        attr.height = file.height
      if (file.width)
        attr.width = file.width

      this.editor
        .chain()
        .focus()
        .setImage(attr)
        .run()
    })
  }

  private handleAddTable(form: { cols: string, header: boolean, rows: string }) {
    this.editor.chain().focus().insertTable({ cols: +form.cols, rows: +form.rows, withHeaderRow: form.header }).run()
  }

  private closeChooseColor(e: MouseEvent) {
    const clickChooseColor = (e.target as Element).closest('.js-color-select')
    const clickChooseMarker = (e.target as Element).closest('.js-marker-select')
    const clickChooseTable = (e.target as Element).closest('.js-table-select')
    if ((this.showChooseColor || this.showBubbleChooseColor) && (!this.colorSelect.includes(clickChooseColor as Element))) {
      this.showChooseColor = false
      this.showBubbleChooseColor = false
    }

    if ((this.showChooseMarker || this.showBubbleChooseMarker) && (!this.markerSelect.includes(clickChooseMarker as Element))) {
      this.showChooseMarker = false
      this.showBubbleChooseMarker = false
    }

    if ((this.showChooseTable || this.showBubbleChooseTable) && (!this.tableSelect.includes(clickChooseTable as Element))) {
      this.showChooseTable = false
      this.showBubbleChooseTable = false
    }
  }

  private changeTable(action: string) {
    this.editor.chain().focus()[action]().run()
    this.showChooseTable = false
  }

  // Задать цвет
  private setColor(color: string) {
    this.editor.chain().focus().setColor(color).run()
    this.color = color
    this.showChooseColor = false
    this.showBubbleChooseColor = false
  }

  // Сбросить цвет
  private unsetColor() {
    this.editor.chain().focus().unsetColor().run()
    this.color = ''
    this.showChooseColor = false
    this.showBubbleChooseColor = false
  }

  // Задать маркер
  private setMarker(marker: string) {
    this.editor.chain().focus().setHighlight({ color: marker }).run()
    this.marker = marker
    this.showChooseMarker = false
    this.showBubbleChooseMarker = false
  }

  // Сбросить маркер
  private unsetMarker() {
    this.editor.chain().focus().unsetHighlight().run()
    this.marker = ''
    this.showChooseMarker = false
    this.showBubbleChooseMarker = false
  }

  private clickChooseColor(isBubble = false) {
    if (isBubble) {
      this.showBubbleChooseColor = !this.showBubbleChooseColor
      this.showChooseMarker = false
      this.showChooseTable = false
      return
    }
    this.showChooseColor = !this.showChooseColor
    this.showChooseMarker = false
    this.showChooseTable = false
  }

  private clickChooseMarker(isBubble = false) {
    if (isBubble) {
      this.showBubbleChooseMarker = !this.showBubbleChooseMarker
      this.showBubbleChooseColor = false
      this.showBubbleChooseTable = false
      return
    }
    this.showChooseMarker = !this.showChooseMarker
    this.showChooseColor = false
    this.showChooseTable = false
  }

  private clickChooseTable(isBubble = false) {
    if (isBubble) {
      this.showBubbleChooseTable = !this.showBubbleChooseTable
      this.showBubbleChooseMarker = false
      this.showBubbleChooseColor = false
      return
    }
    this.showChooseTable = !this.showChooseTable
    this.showChooseMarker = false
    this.showChooseColor = false
  }

  private handleEditorReady () {
    this.$emit('load')
  }

  @Watch('readonly')
  private watchReadonly() {
    this.isPreview = this.readonly
  }

  @Watch('isPreview')
  private watchIsPreview(value: boolean) {
    if (value) {
      if (this.$refs.toolbar) {
        this.observer.unobserve((this.$refs.toolbar as Vue).$el as Element)
        this.toolbarHeight = 0
      }
    } else {
      this.$nextTick(() => {
        this.observer.observe((this.$refs.toolbar as Vue).$el as Element)
      })
    }
  }

  @Watch('showChooseColor')
  private watchShowChooseColor(value: boolean) {
    EditorModule.setFocusMenu({ key: 'colorChoose', value })
  }

  @Watch('showChooseMarker')
  private watchShowChooseMarker(value: boolean) {
    EditorModule.setFocusMenu({ key: 'markerChoose', value })
  }

  @Watch('showChooseTable')
  private watchShowChooseTable(value: boolean) {
    EditorModule.setFocusMenu({ key: 'tableChoose', value })
  }
}
