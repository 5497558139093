































































import { Component } from 'vue-property-decorator'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import AuthModule from '@/store/modules/auth'
import MasterTutorialsModule from '@/store/modules/master/tutorials'

@Component
export default class LessonExerciseTutorial extends NotifyMixin {
  private get positionReset() {
    return this.$vuetify.breakpoint.width < 700
  }

  private get isTourStart() {
    return MasterTutorialsModule.lessonExerciseTutorialActive
  }

  // Шаги, когда туториал стартует на странице уроков
  private lessonFirstSteps = [
    {
      before: (type: 'next' | 'previous') => new Promise(resolve => {
        if (type === 'previous')
          this.$router.replace({
            name: 'master.courses.lessons',
          })
            .then(() => {
              resolve(true)
            })
        else
          return resolve(true)
      }),
      content: 'Чтобы найти уроки за предыдущие месяцы, тебе необходимо <strong>переключить месяц</strong> в окошке.',
      header: {
        title: 'Уроки',
      },
      options: {
        labels: {
          buttonNext: 'Домашние работы',
        },
      },
      params: {
        enableScrolling: false,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [-16, 24],
            },
          },
        ],
        placement: this.positionReset ? 'bottom' : 'right-start',
      },
      target: '#lesson-step',
    },
    {
      before: (type: 'next' | 'prev') => new Promise(resolve => {
        if (type === 'prev')
          return resolve(true)
        this.$router.replace({
          name: 'master.exercises',
        })
          .then(() => {
            resolve(true)
          })
      }),
      content: 'В домашних работах ты также можешь смотреть задания за предыдущие месяцы. Для этого также <strong>переключи месяц</strong> в окошке.',
      header: {
        title: 'Домашние работы',
      },
      options: {
        labels: {
          buttonPrevious: 'Уроки',
        },
      },
      params: {
        enableScrolling: false,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [-16, 24],
            },
          },
        ],
        placement: this.positionReset ? 'bottom' : 'right-start',
      },
      target: '#exercise-step',
    },
  ]

  // Шаги, когда туториал стартует на странице домашек
  private exerciseFirstSteps = [
    {
      before: (type: 'next' | 'previous') => new Promise(resolve => {
        if (type === 'previous')
          this.$router.replace({
            name: 'master.exercises',
          })
            .then(() => {
              resolve(true)
            })
        else
          return resolve(true)
      }),
      content: 'Чтобы найти домашние работы за предыдущие месяцы, тебе необходимо <strong>переключить месяц</strong> в окошке.',
      header: {
        title: 'Домашние работы',
      },
      options: {
        labels: {
          buttonNext: 'Уроки',
        },
      },
      params: {
        enableScrolling: false,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [-16, 24],
            },
          },
        ],
        placement: this.positionReset ? 'bottom' : 'right-start',
      },
      target: '#exercise-step',
    },
    {
      before: (type: 'next' | 'prev') => new Promise(resolve => {
        if (type === 'prev')
          return resolve(true)
        this.$router.replace({
          name: 'master.courses.lessons',
        })
          .then(() => {
            resolve(true)
          })
      }),
      content: 'В уроках ты также можешь смотреть уроки за предыдущие месяцы. Для этого также <strong>переключи месяц</strong> в окошке.',
      header: {
        title: 'Уроки',
      },
      options: {
        labels: {
          buttonPrevious: 'Домашние работы',
        },
      },
      params: {
        enableScrolling: false,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [-16, 24],
            },
          },
        ],
        placement: this.positionReset ? 'bottom' : 'right-start',
      },
      target: '#lesson-step',
    },
  ]

  private steps: any[] = []

  // флаг отправки метрики
  // нужен, что метрика не отправлялась дважды: при переходе на следующий шаг и по завершению туториала
  private isMetricSent = false

  private callbacks = {
    onNextStep: this.nextStep,
    onSkip: this.tourDestroy,
    onStart: this.tourStart,
    onStop: this.tourDestroy,
  }

  private tourStart() {
    MasterTutorialsModule.setMiniTutorialActivity(true)
  }

  private nextStep() {
    try {
      this.$metrika.reachGoal('minitut_next')
      this.isMetricSent = true
    } catch {
      // eslint-disable-next-line
      console.error('Отправка метрики заблокирована расширением браузера')
    }
  }

  private completeTour() {
    MasterTutorialsModule.setMiniTutorialActivity(false)
    this.$tours['lesson-exercise-tutorial'].finish()
  }

  private tourDestroy() {
    if (!this.isMetricSent)
      try {
        this.$metrika.reachGoal('minitut_1step')
        this.isMetricSent = true
      } catch {
        // eslint-disable-next-line
        console.error('Отправка метрики заблокирована расширением браузера')
      }
    this.fetchCompleteTour()
    MasterTutorialsModule.setMiniTutorialActivity(false)
  }

  private fetchCompleteTour() {
    if (AuthModule.self && !AuthModule.self.miniTutorialPassed) {
      AuthModule.completeLessonExerciseTour()
        .catch(this.notifyError)
    }
  }

  private startTour(source: 'lesson' | 'exercise') {
    this.steps = source === 'lesson' ? [...this.lessonFirstSteps] : [...this.exerciseFirstSteps]
    setTimeout(() => {
      this.$tours['lesson-exercise-tutorial'].start()
    }, 500)
  }

  private mounted() {
    this.$bus.$on('startLessonExerciseTour', this.startTour)
  }
}
